import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto';

// Connects to data-controller="form"
export default class extends Controller {
  connect() {
    let month_data = JSON.parse(this.element.dataset.values);
    let labels = Object.keys(month_data);
    let values = Object.values(month_data);

    this.chart = new Chart(this.element, {
      data: {
        datasets: [{
          type: 'bar',
          data: values
        }, {
          type: 'line',
          data: values,
          cubicInterpolationMode: 'monotone',
          tension: 0.4
        }],
        labels: labels
      },
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          y: {
            display: true,
            title: {
              display: true,
              text: 'Total earnings (€)',
              font: {
                weight: 'bold'
              }
            }
          }
        }
      }
    });
  }
}