import { Controller } from "@hotwired/stimulus"

import DateRangePicker from 'flowbite-datepicker/DateRangePicker';
import Datepicker from 'flowbite-datepicker/Datepicker';

// Connects to data-controller="datepicker"
export default class extends Controller {
  connect() {
    const dpEl = this.element;
    const options = {
      todayHighlight: true,
      autohide: true,
      clearBtn: true,
      weekStart: 1,
      format: 'dd/mm/yyyy',
      orientation: 'bottom'
    }

    if(dpEl.dataset.picker_type=='range'){
      const picker = new DateRangePicker(dpEl, options);
    }else{
      const picker = new Datepicker(dpEl, options);
    }
  }
}