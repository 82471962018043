// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

/** JQUERY **/
import jquery from 'jquery';
window.jQuery = jquery;
window.$ = jquery;

/** ALPINEJS **/
import Alpine from 'alpinejs'
window.Alpine = Alpine;
Alpine.start();

/** PLUGINS **/
import 'flowbite';

/** CUSTOM **/
import './main';